.srt-config .p-input-label {
    color: var(--surface-900);
}

.srt-config .p-text-label {
    color: var(--surface-500);
    font-weight: 500;
}

.srt-config .p-input-group {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 50%;
    flex-direction:column;
}